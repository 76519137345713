import { cn } from "@trident/ui";
import { Label } from "@trident/ui/label";
import { RadioGroup, RadioGroupItem } from "@trident/ui/radio-group";
import { ScrollArea } from "@trident/ui/scroll-area";
import { Skeleton } from "@trident/ui/skeleton";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useAuth } from "~/providers/auth2";
import { api, RouterOutputs } from "~/trpc/react";

type Policy = RouterOutputs["form"]["accessiblePolicies"][number];

export function PolicyPicker({
  selectedPolicy,
  setSelectedPolicy,
}: {
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: Dispatch<SetStateAction<Policy | undefined>>;
}) {
  const utils = api.useUtils();
  const { ready, session, type } = useAuth();
  const { data, isLoading } = api.form.accessiblePolicies.useQuery(undefined, {
    enabled: !!ready && !!session && type === "user",
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const policies = useMemo(() => {
    if (!data) return [];

    let ownerCount = 0;
    let otherCount = 0;

    return data
      .map((policy) => {
        const isOwner = policy.owner_email === session?.email;
        return {
          ...policy,
          count: isOwner ? ++ownerCount : ++otherCount,
        };
      })
      .sort((a, b) => a.count - b.count);
  }, [data, session?.email]);

  return isLoading ? (
    <div className="flex flex-col gap-2">
      <Skeleton className="h-16 w-full" />
      <Skeleton className="h-16 w-full" />
      <Skeleton className="h-16 w-full" />
    </div>
  ) : !!data && policies.length > 0 ? (
    <ScrollArea className="flex h-72 w-full flex-col items-center justify-center">
      <div className="flex flex-col gap-2">
        {policies.map((policy) => (
          <RadioGroup
            value={selectedPolicy?.policyID}
            onValueChange={(v) => {
              const policy = data?.find((policy) => policy.policyID === v);
              if (policy) {
                setSelectedPolicy(policy);
                if (policy.status === "incomplete") {
                  utils.form.sync.prefetch({ policyID: v });
                  utils.form.statuses.prefetch({ policyID: v });
                }
              } else {
                console.error("Policy not found");
              }
            }}
            key={policy.policyID}
          >
            <Label
              htmlFor={policy.policyID}
              className="flex min-h-[84px] w-full cursor-pointer items-center rounded-sm border border-grayscale-7 px-5 shadow-card-shadow [&:has([data-state=checked])]:bg-[#D8EAEA]"
            >
              <div className="flex w-full items-center justify-between">
                <div className="grid items-center gap-1.5">
                  <h3
                    className={cn(
                      "text-xl font-medium uppercase leading-6",
                      !selectedPolicy
                        ? "text-dark-teal-2"
                        : selectedPolicy?.policyID === policy.policyID
                          ? "text-dark-teal-2"
                          : "text-grayscale-6",
                    )}
                  >
                    {session?.email === policy.owner_email
                      ? `Your Application ${policy.count}`
                      : `${policy.owner_first_name + "'s" ?? "Their"} Application ${policy.count}`}
                  </h3>
                  <div className="flex gap-2 capitalize">
                    <p
                      className={cn(
                        "text-xl font-medium uppercase leading-6 text-dark-teal-5",
                        !selectedPolicy
                          ? "text-dark-teal-5"
                          : selectedPolicy?.policyID === policy.policyID
                            ? "text-dark-teal-5"
                            : "text-grayscale-6",
                      )}
                    >
                      {
                        {
                          personal: "Personal",
                          enterprise: "Enterprise",
                          stakeholder: "Stakeholder",
                          keyHolder: "Key Holder",
                        }[policy.type]
                      }
                    </p>
                    <p
                      className={cn(
                        "text-lg leading-6",
                        !selectedPolicy
                          ? "text-sys-orange-1"
                          : selectedPolicy?.policyID === policy.policyID
                            ? policy.status === "requires_action" ||
                              policy.status === "incomplete" ||
                              policy.status === "pending"
                              ? "text-sys-orange-1"
                              : policy.status === "approved"
                                ? "text-sys-green-1"
                                : "text-sys-red-1"
                            : "text-grayscale-6",
                        policy.status === "approved" && "text-sys-green-1",
                        policy.status === "rejected" && "text-sys-red-1",
                      )}
                    >
                      {policy.status === "requires_action"
                        ? "requires action"
                        : policy.status}
                    </p>
                  </div>
                </div>
                <div>
                  <RadioGroupItem
                    value={policy.policyID}
                    id={policy.policyID}
                  />
                </div>
              </div>
            </Label>
          </RadioGroup>
        ))}
      </div>
    </ScrollArea>
  ) : (
    <div>No policies found</div>
  );
}
