import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";

import { cn } from "@trident/ui";
import { cva } from "class-variance-authority";

const labelVariants = cva(
  "peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      variant: {
        appForm: "text-xl font-medium leading-5 text-dark-teal-2",
        default:
          "text-lg font-normal uppercase leading-5 text-dark-teal-2 dark:text-light-teal-2",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface LabelProp
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
    VariantProps<typeof labelVariants> {}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  LabelProp
>(({ className, variant, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({ variant, className }))}
    {...props}
  />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
