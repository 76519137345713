"use client";
import { useAuth } from "~/providers/auth2";
import InsuranceApplicationIllustrationDialog from "../components/illustration-dialog";
import { useRouter } from "next/navigation";
import { api, RouterOutputs } from "~/trpc/react";
import { PolicyPicker } from "~/components/policy-picker";
import { useMemo, useState } from "react";
import InsuranceApplicationDialog from "~/components/dialog";
import { skipToken } from "@tanstack/react-query";
import { toast } from "@trident/ui/toast";
import Image from "next/image";
import WelcomeLaptop from "../../public/images/welcome-laptop.svg";
import { buttonVariants } from "@trident/ui/button";
import Link from "next/link";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@trident/ui/tooltip";

type Policy = RouterOutputs["form"]["accessiblePolicies"][number];

export default function Landing() {
  const [selectedPolicy, setSelectedPolicy] = useState<Policy | undefined>(
    undefined,
  );

  const router = useRouter();
  const { ready, type, session } = useAuth();
  const { isPending } = api.form.accessiblePolicies.useQuery(undefined, {
    enabled: !!ready && !!session && type === "user",
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const statuses = api.form.statuses.useQuery(
    !!selectedPolicy ? { policyID: selectedPolicy.policyID } : skipToken,
    {
      enabled: !!selectedPolicy,
    },
  );

  const completed = useMemo(() => {
    if (!statuses.data) return false;
    return Object.values(statuses.data).every(
      (status) => status === "complete",
    );
  }, [statuses.data]);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-2">
      {session ? (
        <InsuranceApplicationIllustrationDialog
          title="Select Application to Continue"
          subtitle="You are signed in"
          info={{
            prompt: "Need help",
            content:
              "If you need support at any point during the application process contact agent@anchorwatch.com",
          }}
          buttonCallback={() => {
            const flowType = selectedPolicy?.type;
            const policyID = selectedPolicy?.policyID;
            const status = selectedPolicy?.status;

            if (!flowType || !policyID || !status) {
              toast(
                <div>
                  Missing policy information. Please select a policy and try
                  again.
                </div>,
              );
              return;
            }

            switch (flowType) {
              case "personal":
              case "enterprise": {
                if (status === "incomplete") {
                  router.push(
                    `/insurance-application/${flowType}/${selectedPolicy.started ? "start" : "welcome"}/${policyID}`,
                  );
                } else {
                  router.push(
                    `/insurance-application/status?policyID=${policyID}`,
                  );
                }
                break;
              }

              case "stakeholder":
              case "keyHolder": {
                if (completed) {
                  router.push(
                    `/insurance-application/status?policyID=${policyID}`,
                  );
                } else {
                  router.push(
                    `/insurance-application/${flowType}/${selectedPolicy.started ? "start" : "welcome"}/${policyID}`,
                  );
                }
                break;
              }
            }
          }}
          buttonDisabled={isPending || !selectedPolicy}
          buttonText={isPending ? "Loading..." : "Continue"}
        >
          <div className="flex h-full flex-col justify-between">
            <div className="flex flex-col gap-5">
              <h4 className="text-lg leading-6 tracking-tight text-dark-teal-5">
                Please select an application to continue.
              </h4>
              <PolicyPicker
                selectedPolicy={selectedPolicy}
                setSelectedPolicy={setSelectedPolicy}
              />
            </div>
          </div>
        </InsuranceApplicationIllustrationDialog>
      ) : (
        <InsuranceApplicationDialog
          title="AnchorWatch Application"
          disableBackButton
          disableProgressBar
        >
          <div className="flex h-full flex-col justify-between gap-6 py-6">
            <h1 className="text-xl font-medium leading-6 tracking-tight">
              You need to log in to continue with your application. If you
              don&apos;t have an account, please reach out to an agent.
            </h1>
            <Image
              src={WelcomeLaptop}
              alt="Welcome Laptop"
              priority
              quality={100}
              className="h-72 w-auto 2xl:h-auto"
            />
            <div className="flex gap-4">
              <Link
                className={buttonVariants({
                  className: "h-[50px] text-base",
                  variant: "default",
                })}
                href="/auth/signin"
              >
                Log in
              </Link>

              <TooltipProvider>
                <Tooltip delayDuration={200}>
                  <TooltipTrigger
                    className={buttonVariants({
                      className: "h-[50px] text-base",
                      variant: "secondary",
                    })}
                  >
                    Contact Agent
                  </TooltipTrigger>
                  <TooltipContent
                    align="start"
                    className="max-w-[320px] rounded border-[1px] border-solid border-dark-teal-5 bg-white p-[10px] text-sm text-dark-teal-5"
                  >
                    Email us:
                    <strong> agent@anchorwatch.com</strong>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </InsuranceApplicationDialog>
      )}
    </div>
  );
}
